import { push } from 'connected-react-router';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import type {
  AgreementTypes,
  ApplicationsState,
  CustomerInfo,
  Dispatch,
  ExistingJointOwner,
  ExistingJointOwners,
  GetState,
  GetTermsResponse,
  ServiceFunction,
  ServiceThunk,
  SubmitApplicationClientResponse,
} from '../../utilities/types';
import { cleanObject } from '../../utilities/cleanObject';
import { decrypt, encrypt } from '../../utilities/crypt';
import { setSessionParam, isSessionParamValid } from '../../utilities/sessionID';
import sessionVariables from '../../utilities/sessionIdConstants';
import {
  getAOTermsAndConditions,
  getAOTermsAndConditionsTransmit,
} from '../../components/terms/terms.service';
import {
  JOINT_OWNERS_LISTS_ID,
  JOINT_OWNERS_SECTION_ID,
  NEW_JOINT_OWNERS_ID,
  SELECTED_EXISTING_JOINT_OWNERS_ID,
} from '../../form.constants';
import type { NewJointOwners, NewJointOwner } from './jointOwners/typings';
import type { AllAccount } from '../../domain/Account/AllAccount';
import {
  clearFlashMessage,
  setFlashMessage,
} from '../../components/flashMessage/flashMessage.reducer';
import type {
  CustomerApplicationBody,
  CustomerApplicationTransmitBody,
  CustomerSearchRequestBody,
  PartialCustomerApplicationBody,
  PartialExistingCustomerApplicant,
  PartialNewCustomerApplicant,
  NewCustomerApplicant,
  ProductSelect,
  ValidateNewCustomerApplicationResponse,
  PartialApplicant,
} from './applications.contracts.types';
import { formatFieldName, parseFieldNameValues } from '../../formatters/fieldNameValues';
import { INITIALS_PREFIX } from '../../components/agreement/termsSection.constants';
import {
  ACTION_ANALYTICS_FUNDING_FLOW,
  ACTION_APPLICATION_ERROR,
  ACTION_CLEAR_ANALYTICS_FUNDING_FLOW,
  ACTION_CONFIRM_NEW_PHONE_NUMBER,
  ACTION_CREATE_APPLICATION_REQUEST,
  ACTION_CREATE_EXISTING_USER_APPLICATION_SUCCESS,
  ACTION_CREATE_NEW_USER_APPLICATION_FAILURE,
  ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS,
  ACTION_DISPLAY_TERMS_AND_CONDITIONS,
  ACTION_FETCH_CUSTOMER_INFO_SUCCESS,
  ACTION_LOAD_JOINT_OWNERS,
  ACTION_SET_CONTACT_US_CODE,
  ACTION_SET_LOADING_FALSE,
  ACTION_SET_LOADING_TRUE,
  ACTION_STORE_NEW_ACCOUNT_INFO,
  ACTION_TERMS_AND_CONDITIONS_ERROR,
} from './applications.reducer';
import { UPDATE_TRANSMIT_STATUS } from './applicationsTransmit.reducer';
import type {
  CustomerSearchResponse,
  FetchCustomerIdType,
  FetchCustomerInfoType,
  FetchEncryptedCustomerTaxIdType,
} from './applications.service';
import {
  createApplicationService,
  fetchCustomerIdService,
  fetchEncyptedCustomerTaxIdService,
  fetchJointOwnersService,
  fetchJointOwnersV2Service,
  getCustomerInfoService,
  getTransmitCustomerInfoService,
  validateApplicationService,
  createApplicationTransmitService,
} from './applications.service';
import {
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import type { AccountFundingFlow } from '../../utilities/accountFundingFlowType';
import worker from '../../workers/singletonWorker';
import trimSuperfluousSpaces from '../../formatters/trimSuperfluousSpaces';
import Routes from '../routes/routes.constants';
import {
  ANALYTICS_REVIEW_TERMS,
  ANALYTICS_REVIEW_TERMS_TRANSMIT,
  ANALYTICS_SUBMIT_APPLICATION_ERROR,
  ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR,
} from '../../analytics/actions';
// eslint-disable-next-line import/no-cycle
import { TRANSMIT_RESPONSE } from '../otp/otp.constants';
import { getBlackBox } from '../authenticate/blackBox.service';
// eslint-disable-next-line import/no-cycle
import { extractTermsAndWithholding } from './termsAndConditions/termsAndConditions.utilities';
import { isNAOFlow } from '../../utilities/accountOpeningFlowType';
import getTransmitApplicantId from '../../utilities/getTransmitApplicantId';
import { ReduxState } from '../../reducers';

type ExistingJointOwnersService = () => Promise<ExistingJointOwners>;

export const NEW_EXTERNAL_FUNDING_SOURCE = 'NEW_EXTERNAL';

export type NewExternalAccountFundingSource = {
  fundingSourceType: 'NEW_EXTERNAL';
  routingNumber: string;
  accountNumber: string;
  accountType: string;
};

export type NewAccountFundingInfo = {
  fromAccount: NewExternalAccountFundingSource;
  toAccount: {
    productId: string;
    accountId: string;
    accountType: string;
  };
  amount: string;
};

export const fetchAOTermsAndConditions =
  (service: ServiceThunk<GetTermsResponse> = getAOTermsAndConditions) =>
  async (dispatch: Dispatch, getState: GetState): Promise<GetTermsResponse> => {
    dispatch({ type: ACTION_SET_LOADING_TRUE });
    const { applications, authenticate } = getState();
    let { terms } = applications;
    const hasTermsAndConditions = terms?.agreements?.length && terms?.etin?.length;
    if (!hasTermsAndConditions) {
      try {
        terms = await service();
      } catch (e) {
        dispatch({ type: ACTION_TERMS_AND_CONDITIONS_ERROR });
      } finally {
        dispatch({ type: ACTION_SET_LOADING_FALSE });
      }
      dispatch({
        type: ACTION_DISPLAY_TERMS_AND_CONDITIONS,
        payload: { terms },
      });
      if (!authenticate.isLoggedIn) {
        dispatch({
          type: ANALYTICS_REVIEW_TERMS,
        });
      }
    }
    return terms;
  };

export const fetchAOTermsAndConditionsTransmit =
  (service: ServiceThunk<GetTermsResponse> = getAOTermsAndConditionsTransmit) =>
  async (dispatch: Dispatch, getState: GetState): Promise<GetTermsResponse> => {
    const { applications, authenticate } = getState();
    let { terms } = applications;
    const hasTermsAndConditions = terms?.agreements?.length;
    if (!hasTermsAndConditions) {
      dispatch({ type: ACTION_SET_LOADING_TRUE });
      try {
        terms = await service();
      } catch (e) {
        dispatch({ type: ACTION_TERMS_AND_CONDITIONS_ERROR });
      } finally {
        dispatch({ type: ACTION_SET_LOADING_FALSE });
      }
      dispatch({
        type: ACTION_DISPLAY_TERMS_AND_CONDITIONS,
        payload: { terms },
      });
      if (!authenticate.isLoggedIn) {
        dispatch({
          type: ANALYTICS_REVIEW_TERMS_TRANSMIT,
        });
      }
    }
    return terms;
  };

export const fetchJointOwners =
  (service: ServiceThunk<ExistingJointOwners> = fetchJointOwnersService) =>
  (dispatch: Dispatch) => {
    return (
      service()
        .then((payload) => {
          dispatch({
            type: ACTION_LOAD_JOINT_OWNERS,
            payload,
          });
        })
        // Fails silently by returning an empty list of Joint Owners
        .catch(() => {
          dispatch({
            type: ACTION_LOAD_JOINT_OWNERS,
            payload: [],
          });
        })
    );
  };

export const fetchJointOwnersTransmit =
  (service: ExistingJointOwnersService = fetchJointOwnersV2Service) =>
  (dispatch: Dispatch) => {
    return service()
      .then((payload) => {
        dispatch({
          type: ACTION_LOAD_JOINT_OWNERS,
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTION_LOAD_JOINT_OWNERS,
          payload: [],
        });
      });
  };

export type NewAccountInfo = {
  newAccount: AllAccount;
  newProductId: string;
  funded: boolean;
};

export const storeNewAccountInfo = (newAccountInfo: NewAccountInfo) => (dispatch: Dispatch) =>
  dispatch({
    type: ACTION_STORE_NEW_ACCOUNT_INFO,
    payload: newAccountInfo,
  });

export type AddressFields = {
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipCode: string;
  phoneType: string;
  phoneNumber?: string;
  noPhoneNumber: boolean;
};

export type EmailField = {
  email: string;
};

export type AboutYouFields = {
  firstName: string;
  middleName?: string;
  lastName: string;
  socialSecurityNumber: string;
  dateOfBirth: string;
};

export type MoreAboutYouFields = {
  citizenship: string;
  occupation: string;
  employerName?: string;
  securityQuestion: string;
  securityAnswer: string;
};

type Agreements = {
  [key: string]: boolean;
};
export type Terms = {
  agreements?: Agreements;
  etin?: {
    [key: string]: boolean;
    ['terms-withholding']: boolean;
  };
};

type UntypedFormData = {
  formData?: NewCustomerApplicationForm | ExistingCustomerApplicationForm;
  terms: Terms;
};

export type JointOwnersSection = {
  // JOINT_OWNERS_LISTS_ID
  jointOwnersLists: {
    // NEW_JOINT_OWNERS_ID
    newJointOwners: NewJointOwners;
    // SELECTED_EXISTING_JOINT_OWNERS_ID
    selectedExistingJointOwners: ExistingJointOwners;
  };
};

export type PartialNewJointOwner = {
  timestamp?: string;
  jointAddress?: Partial<EmailField & AddressFields & MoreAboutYouFields>;
  jointDetails?: Partial<AboutYouFields>;
};

export type NewCustomerApplicationForm = {
  affinityCompanyCode: string;
  personalInformation: AboutYouFields & EmailField & AddressFields;
  moreAboutYou: MoreAboutYouFields;
  fundingMethod: string;
  terms: Terms;
  product: ProductSelect;
  jointOwnersSection: JointOwnersSection;
  phoneNumber?: string;
  phoneType?: string;
  noPhoneNumber?: boolean;
};

// CustomerApplicationForm with all recursive properties marked as optional
export type PartialCustomerApplicationForm = {
  // https://github.com/facebook/flow/issues/5702
  moreAboutYou?: Partial<MoreAboutYouFields>;
  fundingMethod?: string;
  terms?: Partial<Terms>;
  product?: Partial<ProductSelect>;
  personalInformation?: Partial<AboutYouFields & EmailField & AddressFields>;
  // JOINT_OWNERS_SECTION_ID
  jointOwnersSection?: {
    // JOINT_OWNERS_LISTS_ID
    jointOwnersLists?: {
      // NEW_JOINT_OWNERS_ID
      newJointOwners?: Array<PartialNewJointOwner>;
      // SELECTED_EXISTING_JOINT_OWNERS_ID
      selectedExistingJointOwners?: Array<Partial<ExistingJointOwner>>;
    };
  };
};

export type ExistingCustomerApplicationForm = {
  jointOwnersSection: JointOwnersSection;
  product: ProductSelect;
  terms: Terms;
  phoneNumber?: string;
  phoneType?: string;
  noPhoneNumber?: boolean;
  noPhoneADA?: boolean;
};

// We need to remove the initials fields from the agreements objects. The backend does not
// expect to receive the initials and will fail if it does.
export const filterInitialsFromAgreements = (agreements: Agreements = {}) =>
  Object.keys(agreements)
    .filter((agreementKey) => !agreementKey.startsWith(INITIALS_PREFIX))
    .reduce<Record<string, boolean>>((map, agreementKey) => {
      const newAgreements = { ...map };
      newAgreements[agreementKey] = agreements[agreementKey];
      return newAgreements;
    }, {});

const getTermsAccepted = (terms?: Terms) => {
  const parsedAgreements =
    terms &&
    terms.agreements &&
    parseFieldNameValues(filterInitialsFromAgreements(terms.agreements));
  const parsedEtin = terms && terms.etin && parseFieldNameValues(terms.etin);
  return { ...parsedAgreements, ...parsedEtin };
};

export const buildExistingApplicant = (
  customerId?: string,
  terms?: Terms,
  phoneNumber?: string,
  phoneType?: string,
  noPhoneNumber?: boolean
): PartialExistingCustomerApplicant => ({
  existingCustomerInformation: {
    customerId,
    phoneNumber: !noPhoneNumber ? phoneNumber : undefined,
    phoneType: phoneNumber && !noPhoneNumber ? phoneType : undefined,
    noPhoneIndicator: noPhoneNumber,
  },
  termsAccepted: getTermsAccepted(terms),
});

export const buildPrimaryApplicant = async (
  formData: PartialCustomerApplicationForm,
  affinityCompanyCode?: string
): Promise<PartialNewCustomerApplicant> => {
  const { personalInformation = {}, moreAboutYou = {}, terms } = formData;
  const encryptedTaxId =
    personalInformation.socialSecurityNumber &&
    (await encrypt(personalInformation.socialSecurityNumber));

  return {
    newCustomerInformation: {
      affinityCompanyCode,
      name: {
        first: trimSuperfluousSpaces(personalInformation.firstName),
        middle: trimSuperfluousSpaces(personalInformation.middleName),
        last: trimSuperfluousSpaces(personalInformation.lastName),
      },
      dateOfBirth: personalInformation.dateOfBirth,
      nationality: moreAboutYou.citizenship,
      employment: {
        occupation: moreAboutYou.occupation,
        employer: trimSuperfluousSpaces(moreAboutYou.employerName),
      },
      taxId: encryptedTaxId,
      emailAddress: personalInformation.email,
      noPhoneIndicator: personalInformation.noPhoneNumber,
      phoneNumber: personalInformation.phoneNumber,
      phoneType: personalInformation.noPhoneNumber ? undefined : personalInformation.phoneType,
      primaryAddress: {
        line1: trimSuperfluousSpaces(personalInformation.streetAddress1),
        city: trimSuperfluousSpaces(personalInformation.city),
        state: personalInformation.state,
        zipCode: personalInformation.zipCode,
        unitNumber: trimSuperfluousSpaces(personalInformation.streetAddress2),
      },
      securityAnswer: moreAboutYou.securityAnswer && moreAboutYou.securityAnswer.trim(),
      securityQuestion: moreAboutYou.securityQuestion,
    },
    termsAccepted: getTermsAccepted(terms),
  };
};

const buildFormErrors = (
  response: ValidateNewCustomerApplicationResponse
): PartialCustomerApplicationForm => {
  if (response.valid) return {};
  const { jointApplicants = [] } = response;

  const formErrors: Record<string, string> = {};

  if (response.primaryApplicant) {
    const { newCustomerInformation } = response.primaryApplicant as NewCustomerApplicant;
    const { employment, name, primaryAddress } = newCustomerInformation;

    Object.assign(formErrors, {
      personalInformation: {
        firstName: name.first,
        middleName: name.middle,
        lastName: name.last,
        socialSecurityNumber: newCustomerInformation.taxId,
        dateOfBirth: newCustomerInformation.dateOfBirth,
        email: newCustomerInformation.emailAddress,
        streetAddress1: primaryAddress.line1,
        streetAddress2: primaryAddress.unitNumber,
        city: primaryAddress.city,
        state: primaryAddress.state,
        zipCode: primaryAddress.zipCode,
        phoneType: newCustomerInformation.phoneType,
        phoneNumber: newCustomerInformation.phoneNumber,
        noPhoneNumber: newCustomerInformation.noPhoneIndicator,
      },
      moreAboutYou: {
        citizenship: newCustomerInformation.nationality,
        occupation: employment.occupation,
        employerName: employment.employer,
        securityQuestion: newCustomerInformation.securityQuestion,
        securityAnswer: newCustomerInformation.securityAnswer,
      },
    });
  }

  if (jointApplicants.length > 0) {
    const newOwnerErrors = jointApplicants.reduce<Array<PartialNewJointOwner>>((acc, applicant) => {
      const { newCustomerInformation } = applicant as NewCustomerApplicant;

      if (newCustomerInformation) {
        const { name, employment, primaryAddress } = newCustomerInformation;
        acc.push({
          jointDetails: {
            firstName: name.first,
            middleName: name.middle,
            lastName: name.last,
            dateOfBirth: newCustomerInformation.dateOfBirth,
            socialSecurityNumber: newCustomerInformation.taxId,
          },
          jointAddress: {
            citizenship: newCustomerInformation.nationality,
            occupation: employment.occupation,
            employerName: employment.employer,
            email: newCustomerInformation.emailAddress,
            noPhoneNumber: newCustomerInformation.noPhoneIndicator,
            phoneNumber: newCustomerInformation.phoneNumber,
            phoneType: newCustomerInformation.phoneType,
            streetAddress1: primaryAddress.line1,
            streetAddress2: primaryAddress.unitNumber,
            city: primaryAddress.city,
            state: primaryAddress.state,
            zipCode: primaryAddress.zipCode,
            securityAnswer: newCustomerInformation.securityAnswer,
            securityQuestion: newCustomerInformation.securityQuestion,
          },
        });
      }

      return acc;
    }, []);

    Object.assign(formErrors, {
      [JOINT_OWNERS_SECTION_ID]: {
        [JOINT_OWNERS_LISTS_ID]: {
          [NEW_JOINT_OWNERS_ID]: newOwnerErrors,
        },
      },
    });
  }

  return cleanObject(formErrors) || {};
};

export const buildNewJointOwnerApplicant = async (
  newJointOwner: PartialNewJointOwner,
  agreements?: Agreements
): Promise<PartialNewCustomerApplicant> => {
  const { jointDetails = {}, jointAddress = {} } = newJointOwner;
  const ssn = jointDetails.socialSecurityNumber;
  const encryptedTaxId = ssn && (await encrypt(ssn));
  const parsedAgreements =
    agreements && parseFieldNameValues(filterInitialsFromAgreements(agreements));
  return {
    newCustomerInformation: {
      name: {
        first: trimSuperfluousSpaces(jointDetails.firstName),
        middle: trimSuperfluousSpaces(jointDetails.middleName),
        last: jointDetails.lastName.trim(),
      },
      dateOfBirth: jointDetails.dateOfBirth,
      nationality: jointAddress.citizenship,
      employment: {
        occupation: jointAddress.occupation,
        employer: trimSuperfluousSpaces(jointAddress.employerName),
      },
      taxId: encryptedTaxId,
      emailAddress: jointAddress.email,
      noPhoneIndicator: jointAddress.noPhoneNumber,
      phoneNumber: jointAddress.phoneNumber,
      phoneType: jointAddress.noPhoneNumber ? undefined : jointAddress.phoneType,
      primaryAddress: {
        line1: trimSuperfluousSpaces(jointAddress.streetAddress1),
        city: trimSuperfluousSpaces(jointAddress.city),
        state: jointAddress.state,
        zipCode: jointAddress.zipCode,
        unitNumber: trimSuperfluousSpaces(jointAddress.streetAddress2),
      },
      securityAnswer: jointAddress.securityAnswer && jointAddress.securityAnswer.trim(),
      securityQuestion: jointAddress.securityQuestion,
    },
    termsAccepted: parsedAgreements,
  };
};

const buildExistingJointOwnerApplicant = (
  customerId?: string,
  agreements?: Agreements,
  phoneNumber?: string,
  phoneType?: string,
  noPhoneNumber?: boolean
): PartialExistingCustomerApplicant => ({
  existingCustomerInformation: {
    customerId,
    phoneNumber: !noPhoneNumber ? phoneNumber : undefined,
    phoneType: phoneNumber && !noPhoneNumber ? phoneType : undefined,
    noPhoneIndicator: noPhoneNumber,
  },
  termsAccepted: parseFieldNameValues(filterInitialsFromAgreements(agreements)),
});

const buildJointApplicants = async (formData: PartialCustomerApplicationForm) => {
  const { terms = {}, jointOwnersSection = {} } = formData;
  const { jointOwnersLists = {} } = jointOwnersSection;
  const {
    [NEW_JOINT_OWNERS_ID]: newJointOwners = [],
    [SELECTED_EXISTING_JOINT_OWNERS_ID]: existingJointOwners = [],
  } = jointOwnersLists;

  // Initiate building applicants in parallel
  const newJointApplicants = await Promise.all<PartialNewCustomerApplicant>(
    newJointOwners.map((newJointOwner) =>
      buildNewJointOwnerApplicant(newJointOwner, terms.agreements)
    )
  );
  const existingJointApplicants = existingJointOwners.map((existingJointOwner) =>
    buildExistingJointOwnerApplicant(existingJointOwner.customerId, terms.agreements)
  );

  return [...existingJointApplicants, ...newJointApplicants];
};

type BuildApplicationProps = {
  customerId?: string;
  trackingId: string;
  formData: NewCustomerApplicationForm | ExistingCustomerApplicationForm;
  affinityCompanyCode?: string;
  termsInState?: GetTermsResponse;
};

const getDefaultTermValues = (agreements?: AgreementTypes[]) => {
  const defaultTermValues: Record<string, boolean> = {};
  const addDefaultTerms = (agreementTypes?: AgreementTypes[]) => {
    if (!agreementTypes) {
      return;
    }
    agreementTypes.forEach((agreement) => {
      if (agreement.type === 'checkbox' && agreement.key) {
        defaultTermValues[formatFieldName(agreement.key)] = false;
      }
      addDefaultTerms(agreement.children);
      addDefaultTerms(agreement.content);
    });
  };
  addDefaultTerms(agreements);
  return defaultTermValues;
};

// IBWA-1934
// The BSL requires that we send a true / false value for every term checkbox that is shown
// to the user. This method adds a default "false" for every shown checkbox since, if the user
// leaves it unchecked, redux-state defaults to "undefined" rather than "false". Updating to
// final-form may make this method obsolete. In addition, this method depends on the shape of
// the termsInForm object. If that type is updated, this method *must* be revisited to ensure
// appropriate behavior.
const getTermsWithDefaultValues = (termsInForm: Terms, termsInState?: GetTermsResponse): Terms => {
  const termsWithDefaultValues = { ...termsInForm } as const;
  if (!termsInState) {
    return termsWithDefaultValues;
  }
  Object.keys(termsInForm).forEach((termKey) => {
    termsWithDefaultValues[termKey] = {
      ...getDefaultTermValues(termsInState[termKey]),
      ...termsInForm[termKey],
    };
  });
  return termsWithDefaultValues;
};

export async function buildApplication({
  customerId,
  trackingId,
  formData,
  affinityCompanyCode,
  termsInState,
}: BuildApplicationProps): Promise<CustomerApplicationBody> {
  // update terms in formData with default stuff
  const termsWithDefaults = getTermsWithDefaultValues(formData.terms, termsInState);
  const untypedFormData: UntypedFormData = { ...formData, terms: termsWithDefaults };
  const {
    product: formProduct,
    // $FlowShutUp they're all optional in the function, if they're not in the formData that's fine
    phoneNumber,
    // $FlowShutUp
    phoneType,
    // $FlowShutUp
    noPhoneNumber,
  } = formData;

  const primaryApplicant: PartialApplicant = customerId
    ? buildExistingApplicant(customerId, termsWithDefaults, phoneNumber, phoneType, noPhoneNumber)
    : await buildPrimaryApplicant(untypedFormData, affinityCompanyCode);

  const jointApplicants: PartialApplicant[] = await buildJointApplicants(untypedFormData);

  const product = {
    id: formProduct.term,
    fundsSource: formProduct.sourceOfFunds,
    atmCard: formProduct.orderAtmCards,
    checks: formProduct.orderChecks,
    debitCard: formProduct.orderDebitCards,
  } as const;

  if (jointApplicants.length) {
    return {
      primaryApplicant,
      product,
      jointApplicants,
      trackingId,
    };
  }
  return { primaryApplicant, product, trackingId };
}

type SubmitNewUserApplicationOptions = {
  formData: NewCustomerApplicationForm;
  affinityCompanyCode?: string;
  trackingId: string;
};

type SubmitNewUserApplicationTransmitOptions = {
  formData: NewCustomerApplicationForm;
  affinityCompanyCode?: string;
  aoFormId: string;
};

export const submitNewUserApplication =
  (
    { formData, affinityCompanyCode, trackingId }: SubmitNewUserApplicationOptions,
    service: ServiceFunction<
      CustomerApplicationBody,
      SubmitApplicationClientResponse
    > = createApplicationService
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(clearFlashMessage());
    dispatch({ type: ACTION_CREATE_APPLICATION_REQUEST, payload: formData.product.term });

    const { terms: termsInState } = getState().applications;
    const application = await buildApplication({
      formData,
      affinityCompanyCode,
      trackingId,
      termsInState,
    });

    try {
      const payload = await service({ ...application, deviceInfo: { blackbox: getBlackBox() } });
      dispatch({ type: ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS, payload });
      return payload;
    } catch (err) {
      dispatch({ type: ACTION_CREATE_NEW_USER_APPLICATION_FAILURE });
      dispatch({ type: ANALYTICS_SUBMIT_APPLICATION_ERROR, payload: err && err.status });
      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText:
            (err && err.data && err.data.message) || FlashMessageText.CREATE_APPLICATION_ERROR,
        })
      );
    }
    return null;
  };

const buildTransmitMainApplicantInfo = (
  formData: NewCustomerApplicationForm,
  applicantId: string,
  affinityCompanyCode: string,
  applicationsState: ApplicationsState,
  aoFormId: string
) => {
  const { moreAboutYou } = formData;
  const isNAO = isNAOFlow(aoFormId);
  const isWithholding = isNAO
    ? String(formData.terms.etin['terms-withholding']).includes('true')
    : false;

  const customerInformation = applicationsState.customerInfo;

  const userEmployerName = moreAboutYou?.employerName
    ? trimSuperfluousSpaces(moreAboutYou?.employerName)
    : '';

  return {
    applicantId,
    affinityCompanyCode,
    withholding: isWithholding,
    employment: {
      occupation: isNAO
        ? moreAboutYou?.occupation
        : customerInformation?.employments[0]?.occupation.value,
      employer: isNAO ? userEmployerName : customerInformation?.employments[0]?.employerName,
    },
    securityQuestion: isNAO
      ? moreAboutYou?.securityQuestion
      : customerInformation?.secretData[0]?.name,
    'cipher.securityAnswer': isNAO
      ? moreAboutYou?.securityAnswer.trim()
      : customerInformation?.secretData[0]?.value,
    nationality: isNAO ? moreAboutYou?.citizenship : customerInformation?.nationality?.value,
    termsAccepted: extractTermsAndWithholding(formData),
  };
};

const buildTransmitJointApplicantInfo = (
  jointOwnerInfo: NewJointOwner,
  applicantId: string,
  affinityCompanyCode: string,
  termsAccepted: Record<string, boolean>,
  isWithholding: boolean
) => {
  return {
    applicantId,
    affinityCompanyCode,
    withholding: isWithholding,
    employment: {
      occupation: jointOwnerInfo?.jointAddress?.occupation,
      employer: jointOwnerInfo?.jointAddress?.occupation,
    },
    securityQuestion: jointOwnerInfo?.jointAddress?.securityQuestion,
    'cipher.securityAnswer': jointOwnerInfo?.jointAddress?.securityAnswer.trim(),

    nationality: jointOwnerInfo?.jointAddress?.citizenship,
    termsAccepted,
  };
};

const getDateOfBirthAndSocialSecurityNumber = (state: ReduxState, aoFormId: string) => {
  let dateOfBirth;
  let socialSecurityNumber;
  const primaryCustomerInfoEAO = state?.applications?.customerInfo;
  const primaryCustomerInfoNAO = state?.applicationForm?.formNAOData?.personalInformation;

  if (aoFormId === 'newUserAccountOpeningForm') {
    socialSecurityNumber = primaryCustomerInfoNAO?.socialSecurityNumber;
    dateOfBirth = primaryCustomerInfoNAO?.dateOfBirth;
  } else if (aoFormId === 'existingUserAccountOpeningForm') {
    socialSecurityNumber = primaryCustomerInfoEAO?.taxId;
    dateOfBirth = primaryCustomerInfoEAO?.dateOfBirth;
  }
  return { dateOfBirth, socialSecurityNumber };
};

export const submitNewUserTransmitApplication =
  (
    { formData, affinityCompanyCode, aoFormId }: SubmitNewUserApplicationTransmitOptions,
    service: ServiceFunction<
      CustomerApplicationTransmitBody,
      SubmitApplicationClientResponse
    > = createApplicationTransmitService
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(clearFlashMessage());
    dispatch({ type: ACTION_CREATE_APPLICATION_REQUEST, payload: formData.product.term });
    const state = getState();
    const { transmit: transmitState, applications: applicationsState } = state;
    const { applicantId, applicationId } = transmitState;

    const formApplicationIdTransmit = applicationId;
    const product = {
      productId: formData.product.term,
      fundsSource: formData.product.sourceOfFunds,
      atmCard: formData.product.orderAtmCards,
      checks: formData.product.orderChecks,
      debitCard: formData.product.orderDebitCards,
    } as const;

    const { dateOfBirth, socialSecurityNumber } = getDateOfBirthAndSocialSecurityNumber(
      state,
      aoFormId
    );

    // Getting Main Applicant Object from Transmit Response
    const primaryApplicantId = getTransmitApplicantId(
      dateOfBirth,
      socialSecurityNumber,
      applicantId
    );

    // Building Main Transmit Applicant object
    const applicantInfo = buildTransmitMainApplicantInfo(
      formData,
      primaryApplicantId,
      affinityCompanyCode,
      applicationsState,
      aoFormId
    );

    // Building Joint Owner Transmit Applicant objects
    const jointOwnerApplicantIdArray = applicantId.filter(
      (applicant) => applicant.id !== primaryApplicantId
    );

    const termsAccepted = extractTermsAndWithholding(formData);

    const isNAO = isNAOFlow(aoFormId);
    const isWithholding = isNAO
      ? String(formData.terms.etin['terms-withholding']).includes('true')
      : false;

    // THis array will hold the JOINT OWNERS Info
    const jointOwnersInfo = [];

    jointOwnerApplicantIdArray.forEach((jointOwnerId) => {
      const newJointOwner = formData.jointOwnersSection.jointOwnersLists.newJointOwners.filter(
        (jo) =>
          jo.jointDetails.dateOfBirth === jointOwnerId.dob &&
          jo.jointDetails.socialSecurityNumber.slice(-4) === jointOwnerId.las4ssn
      );

      if (newJointOwner.length > 0) {
        const joInfo = buildTransmitJointApplicantInfo(
          newJointOwner[0],
          jointOwnerId.id,
          affinityCompanyCode,
          termsAccepted,
          isWithholding
        );
        jointOwnersInfo.push(joInfo);
      }
    });

    const application = {
      primaryApplicant: {
        ...applicantInfo,
      },
      applicationId: formApplicationIdTransmit,
      ipAddress: '165.225.106.109',
      jointApplicants: [...jointOwnersInfo],
      products: [{ ...product }],
    };

    try {
      const payloadSubmit = await service({ ...application });
      const payload = {
        ...payloadSubmit,
        transmitResponse: transmitState,
      };
      dispatch({ type: ACTION_CREATE_NEW_USER_APPLICATION_SUCCESS, payload });
      return payload;
    } catch (err) {
      dispatch({ type: ACTION_CREATE_NEW_USER_APPLICATION_FAILURE });
      if (
        isNAOFlow(aoFormId) &&
        transmitState.status.responseCode === TRANSMIT_RESPONSE.STATUS_SUCCESS
      ) {
        dispatch({ type: ANALYTICS_SUBMIT_APPLICATION_ERROR, payload: err?.status });
      } else {
        dispatch({ type: ANALYTICS_SUBMIT_APPLICATION_TRANSMIT_ERROR, payload: err?.status });
      }
      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText:
            (err && err.data && err.data.message) || FlashMessageText.CREATE_APPLICATION_ERROR,
        })
      );
    }
    return null;
  };

export const validateNewUserApplication =
  (
    formData: PartialCustomerApplicationForm,
    service: ServiceFunction<
      PartialCustomerApplicationBody,
      ValidateNewCustomerApplicationResponse
    > = validateApplicationService
  ) =>
  async () => {
    const primaryApplicant = await buildPrimaryApplicant(formData);
    const jointApplicants = await buildJointApplicants(formData);
    const application = {
      primaryApplicant,
      jointApplicants: jointApplicants.length > 0 ? jointApplicants : undefined,
    } as const;

    const partialApplication: Record<string, unknown> = cleanObject(application);
    const payload = await service(partialApplication);
    return buildFormErrors(payload);
  };

export const validateNewJointOwner =
  (jointOwner: PartialNewJointOwner) => async (dispatch: ThunkDispatch<null, null, AnyAction>) => {
    // Wrap new joint owner form within global NAO form structure
    const formData: PartialCustomerApplicationForm = {
      jointOwnersSection: {
        jointOwnersLists: {
          newJointOwners: [jointOwner],
          selectedExistingJointOwners: [],
        },
      },
    };

    const errors = await dispatch(validateNewUserApplication(formData));

    let newJointOwnerErrors = {};
    try {
      // Extract errors only for singular new joint owner
      // eslint-disable-next-line prefer-destructuring
      newJointOwnerErrors =
        errors[JOINT_OWNERS_SECTION_ID][JOINT_OWNERS_LISTS_ID][NEW_JOINT_OWNERS_ID][0];
    } catch (e) {
      // Ignore, if no joint owner errors found
    }

    return newJointOwnerErrors;
  };

type SubmitExistingUserApplicationOptions = {
  formData: ExistingCustomerApplicationForm;
  customerId: string;
  trackingId: string;
};

export const submitExistingUserApplication =
  (
    { formData, customerId, trackingId }: SubmitExistingUserApplicationOptions,
    service: ServiceFunction<
      CustomerApplicationBody,
      SubmitApplicationClientResponse
    > = createApplicationService
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(clearFlashMessage());
    dispatch({ type: ACTION_CREATE_APPLICATION_REQUEST, payload: formData.product.term });

    const { terms: termsInState } = getState().applications;
    const application = await buildApplication({ customerId, formData, trackingId, termsInState });

    try {
      const payload = await service(application);
      dispatch({ type: ACTION_CREATE_EXISTING_USER_APPLICATION_SUCCESS, payload });
      return payload;
    } catch (err) {
      dispatch({ type: ACTION_APPLICATION_ERROR });
      dispatch({ type: ANALYTICS_SUBMIT_APPLICATION_ERROR, payload: err && err.status });

      dispatch(
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText:
            (err && err.data && err.data.message) || FlashMessageText.CREATE_APPLICATION_ERROR,
        })
      );
    }
    return null;
  };

// Fetches the customer info of the authenticated customer.
export const fetchCustomerInfo =
  (service: ServiceThunk<CustomerInfo> = getCustomerInfoService) =>
  async (dispatch: Dispatch): Promise<CustomerInfo> => {
    const payload = await service();
    if (payload.customerId && !isSessionParamValid(sessionVariables.SESSION_ID_CUSTOMER))
      setSessionParam(sessionVariables.SESSION_ID_CUSTOMER, payload.customerId);
    dispatch({
      type: ACTION_FETCH_CUSTOMER_INFO_SUCCESS,
      payload: { customerInfo: payload },
    });
    if (payload.etinRequired) dispatch(push(Routes.ETIN_VERIFY_SELF));
    return payload;
  };

export const fetchCustomerInfoTransmit =
  (service: FetchCustomerInfoType = getTransmitCustomerInfoService) =>
  async (dispatch: Dispatch): Promise<CustomerInfo> => {
    const publicKey = await worker.getPublicKey();
    const payload = await service(publicKey);
    const result = await decrypt(payload.taxId);
    const decryptedPayload = { ...payload, taxId: result.payload.toString() };
    if (payload.customerId && !isSessionParamValid(sessionVariables.SESSION_ID_CUSTOMER))
      setSessionParam(sessionVariables.SESSION_ID_CUSTOMER, payload.customerId);
    dispatch({
      type: ACTION_FETCH_CUSTOMER_INFO_SUCCESS,
      payload: { customerInfo: decryptedPayload },
    });
    if (payload.etinRequired) dispatch(push(Routes.ETIN_VERIFY_SELF));
    return payload;
  };

// Given an SSN and DOB, searches for a custId
export const fetchCustomerId = async (
  customerSearch: CustomerSearchRequestBody,
  service: FetchCustomerIdType = fetchCustomerIdService
): Promise<CustomerSearchResponse> => {
  const { taxId, ...rest } = customerSearch;
  const encryptedTaxId = await encrypt(taxId);
  return service({ taxId: encryptedTaxId, ...rest });
};

export const fetchDecryptedCustomerTaxId =
  (service: FetchEncryptedCustomerTaxIdType = fetchEncyptedCustomerTaxIdService) =>
  async (dispatch: Dispatch, getState: GetState): Promise<string> => {
    const { applications } = getState();
    if (applications.customerInfo && applications.customerInfo.decryptedTaxId) {
      return applications.customerInfo.decryptedTaxId;
    }

    try {
      dispatch({ type: 'ACTION_DECRYPT_TAX_ID_REQUEST' });

      const publicKey = await worker.getPublicKey();
      const payload = await service(publicKey);
      const result = await decrypt(payload.taxId);
      const taxId = result.payload.toString();

      dispatch({
        type: 'ACTION_DECRYPT_TAX_ID_SUCCESS',
        payload: taxId,
      });

      return await Promise.resolve('Successfully decrypted id');
    } catch (e) {
      return Promise.reject(new Error('Error Decrypting'));
    }
  };

export const analyticsFundingLocationAction = (pageSubFunction: AccountFundingFlow) => ({
  type: ACTION_ANALYTICS_FUNDING_FLOW,
  payload: pageSubFunction,
});

export const setContactUsCodeAction = (contactUsCode: string) => (dispatch: Dispatch) =>
  dispatch({
    type: ACTION_SET_CONTACT_US_CODE,
    payload: contactUsCode,
  });

export const clearAnalyticsFundingLocationAction = () => ({
  type: ACTION_CLEAR_ANALYTICS_FUNDING_FLOW,
});

export const clearDecryptedTaxId = () => ({
  type: 'ACTION_CLEAR_DECRYPT_TAX_ID',
});

export const confirmNewPhoneNumber = () => ({
  type: ACTION_CONFIRM_NEW_PHONE_NUMBER,
});

export const updateTransmitResponse =
  (transmitResponse: Record<string, string>) => (dispatch: Dispatch) =>
    dispatch({
      type: UPDATE_TRANSMIT_STATUS,
      payload: transmitResponse,
    });
